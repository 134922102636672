import React from "react";
import classNames from "classnames";

import { MockAppView } from "mocks/MockAppView";

// TODO: Refactor to use ApplicationContext
const Introduction = () => {
  return <div>disabled</div>;
  // return (
  //   <MockAppView>
  //     <div className={classNames("mx-4", "my-2")}>
  //       Nothing to see here right now.
  //     </div>
  //   </MockAppView>
  // );
};

export default Introduction;
